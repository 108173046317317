import React from "react";

import { Link } from "react-router-dom";

const SliderItem = (props) => {
  return (
    <div className={props.className}>
      <div className="block__content portfolio__item">
        {/* <Link to={props.route}> */}
        <a href={props.itemHref} target="_blank" rel="noreferrer">
          <img src={props.imgSrc} alt={props.imgAlt} />
        </a>
        {/* </Link> */}
        <div className="portfolio__caption">
          <strong>{props.name}</strong>
          <br />
          <a href={props.itemHref} target="_blank" rel="noreferrer">
            {props.itemHref}
          </a>
          <hr />
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default SliderItem;
